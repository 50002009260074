<template>
  <v-container fluid>
    <v-data-table
      :headers="headers"
      :items="usersWithMembershipType"
      :search="search"
      class="elevation-1"
      style="max-width: 1300px; margin: auto;"
    >
      <template v-slot:top>
        <v-toolbar color="secondary" flat rounded>
          <v-toolbar-title class="white--text">Users</v-toolbar-title>
          <v-divider class="mx-4" inset vertical></v-divider>
          <v-spacer />
          <v-text-field
            v-model="search"
            prepend-inner-icon="mdi-magnify"
            class="col-auto"
            label="Search"
            single-line
            hide-details
            flat
            solo
            dense
            rounded
            clearable
          />
        </v-toolbar>
      </template>
      <template v-slot:[`item.name`]="{ item }">
        <span class="font-weight-bold">{{ item.name }}</span>
      </template>
      <template v-slot:[`item.membershipType`]="{ item }">
        <div class="d-flex justify-center align-center">
          <v-chip
            outlined
            small
            label
          >
            {{ item.membershipType }}
          </v-chip>
        </div>
      </template>

      <template v-slot:[`item.verified`]="{ item }">
        <div class="d-flex justify-center align-center">
          <v-chip
            :color="item.verified ? 'success' : 'error'"
            text-color="white"
            small
          >
            {{ item.verified ? 'Verified' : 'Unverified' }}
          </v-chip>
        </div>
      </template>
      <template v-slot:[`item.actions`]="{ item }">
        <v-btn icon :to="{ name: 'user-edit', params: { id: item.id } }">
          <v-icon small>mdi-pencil-outline</v-icon>
        </v-btn>
        <v-btn icon @click="confirmDelete(item)">
          <v-icon small>mdi-delete-outline</v-icon>
        </v-btn>
      </template>
    </v-data-table>

    <!-- Delete Confirmation Dialog -->
    <v-dialog v-model="deleteDialog" persistent max-width="500px">
      <v-card outlined>
        <v-card-title class="headline">Confirm Deletion</v-card-title>
        <v-card-text>Do you really want to delete user <strong class="primary--text">{{ userToDelete?.name }}</strong>?</v-card-text>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn color="primary" small outlined @click="deleteDialog = false">Cancel</v-btn>
          <v-btn color="primary" small @click="deleteUser">Confirm</v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>

    <v-snackbar :color="snackColor" v-model="snackbar" :timeout="2000">{{ response }}</v-snackbar>
  </v-container>
</template>

<script>
import { mapState, mapGetters } from "vuex";
const fb = require('../../firebaseDb');

export default {
  data() {
    return {
      search: "",
      deleteDialog: false,
      userToDelete: null,
      snackbar: false,
      response: "",
      snackColor: "black",
      headers: [
        {
          text: "Name",
          align: "start",
          value: "name",
          width: "40%",
        },
        { text: "Membership", value: "membershipType" },
        { text: "Email", value: "email", width: "30%" },
        { text: "Status", value: "verified" },
        { text: "Actions", value: "actions", sortable: false },
      ],
    };
  },
  computed: {
    ...mapState(["currentUser", "users"]),
    ...mapGetters(["darkTheme"]),

    usersWithMembershipType() {
      return this.users.map(user => {
        return {
          ...user,
          membershipType: this.getMembershipType(user.role),
        };
      });
    }
  },
  methods: {
    getMembershipType(role) {
      switch (role) {
        case "Tier1":
          return "Premium | Tier 1";
        case "Tier2":
          return "Premium | Tier 2";
        case "Tier3":
          return "Premium | Tier 3";
        case "Tier4":
          return "Free | Tier 4";
        default:
          return role;
      }
    },
    confirmDelete(item) {
      this.userToDelete = item;
      this.deleteDialog = true;
    },
    deleteUser() {
      if (this.userToDelete) {
        fb.usersCollection.doc(this.userToDelete.id).delete().then(() => {
          console.log("User deleted successfully!");
          this.deleteDialog = false;
          this.userToDelete = null;
          this.$store.dispatch("streamUserLists");
          this.snackbar = true;
          this.snackColor = "success";
          this.response = "User deleted successfully!";
          this.userToDelete = "";
        })
        .catch((error) => {
          console.error("Error deleting user:", error);
          this.snackbar = true;
          this.snackColor = "error";
          this.response = "Failed to delete user.";
        });
      }
    },
  },
  mounted() {
    this.$store.dispatch("streamUserLists");
  },
  beforeDestroy() {
    this.$store.dispatch("detachUserList");
  },
};
</script>
